<template>
  <div>
    You do not have the necessary role to access the resource.
  </div>
</template>

<script>

export default {
  name: 'Error403',
  components: {
  },
};
</script>
